import React from "react"
import { Redirect } from "react-router-dom"
import { TPoolRouteProps } from "../../../../routes.d"
import { canUseDom } from "../../../utils/misc-utils"
import { StandardPoolPageContainer } from "../components/PoolPage"

import LoadingView from "../../../components/LoadingView"
import { withoutDomain } from "../../../../common/url-utils"

const PoolHome = (props: TPoolRouteProps) => {
  const { poolData } = props
  return (
    <StandardPoolPageContainer {...props} analyticsFeature="games" analyticsSubfeature={``} analyticsTitle={`Games`}>
      {(canUseDom && poolData.poolDetail && <Redirect to={`${withoutDomain(poolData.poolDetail.url)}/standings`} />) || <LoadingView />}
    </StandardPoolPageContainer>
  )
}

export default PoolHome
